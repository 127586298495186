import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import VehiculosProvider from "./context/VehiculosContext";
import Vehiculos from "./componentes/vehicles/Vehiculos";
import AmpliarVehiculo from "./componentes/vehicles/AmpliarVehiculo";

import "./assets/scss/styles.scss";

import Header from "./componentes/Header/Header";
import Slider from "./componentes/Slider/Slider";
import Footer from "./componentes/Footer/Footer";
import Error404 from "./componentes/Error404";
import Vender from "./componentes/Vender/Vender";
import Gracias from "./componentes/Vender/Gracias";

function App() {
  return (
    <VehiculosProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Slider />
            <Vehiculos />
          </Route>
          <Route path={`/ampliar/:id?`}>
            <Header bg="bgDark" />
            <AmpliarVehiculo />
          </Route>
          <Route path="/-Gracias-vender">
            <Header bg="bgDark" />
            <Gracias />
          </Route>
          <Route path={`/vender`}>
            <Header bg="bgDark" />
            <Vender />
          </Route>
          <Route path="*">
            <Header bg="bgDark" />
            <Error404 />
          </Route>
        </Switch>

        <Footer />
      </Router>
    </VehiculosProvider>
  );
}

export default App;
