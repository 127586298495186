import { Button, Col, Container, Row } from "react-bootstrap";

import checkOk from "../../assets/img/checkOk.svg";

const Gracias = () => {
  return (
    <Container className="pt-5 pb-5">
      <Row>
        <Col className="pt-5 pb-5">
          <div className="w-100 d-flex justify-content-center align-content-center align-items-center loading_box">
            <img src={checkOk} alt="Enviado" style={{ width: "64px" }} />
          </div>
          <p className="desc_moda_vender_paso_2 text-center fw-bold"> Gracias por enviarnos tu solicitud. </p>
          <p className="desc_moda_vender_paso_2 text-center pt-1">Nos pondremos en contacto lo antes posible.</p>
          <div className="d-flex justify-content-center align-content-center align-items-center pt-4">
            <div>
              <button
                className={"vender_siguiente"}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Volver al home
              </button>
            </div>
            <div>
              <Button
                variant="success"
                type="button"
                className="btn_verde_vender ms-4"
                onClick={() => {
                  window.location.href = "/vender";
                }}
              >
                Cargar otro vehículo
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Gracias;
