import pic1 from "../../assets/img/pic1.png";
import pic2 from "../../assets/img/pic2.png";
import pic3 from "../../assets/img/pic3.png";
import Slider from "react-slick";

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const InstructivoFotos = () => {
  return (
    <>
      {
        //DESKTOP VERSION
      }
      <div className="d-md-flex d-none justify-content-around align-content-center align-items-center flex-row pt-4">
        <div className="d-flex justify-content-center flex-column align-content-center align-items-center p-4">
          <div>
            <img src={pic1} alt="" />
          </div>
          <p className="text_modal_instrucciones_inicio pe-4 ps-4 pt-3">
            Busca un lugar amplio y con buena luz donde puedas sacarle fotos a tu vehículo desde varios ángulos. (Serán
            11 fotos en total.)
          </p>
        </div>
        <div className="d-flex justify-content-center flex-column align-content-center align-items-center p-4">
          <div>
            <img src={pic2} alt="" />
          </div>
          <p className="text_modal_instrucciones_inicio pe-2 ps-2 pt-3">
            Intentá que la foto este lo mas enfocada posible y que el vehículo se vea en su totalidad dentro del
            encuadre.
          </p>
        </div>
        <div className="d-flex justify-content-center flex-column align-content-center align-items-center p-4">
          <div>
            <img src={pic3} alt="" />
          </div>
          <p className="text_modal_instrucciones_inicio pe-2 ps-2 pt-3">
            Debes sacar las fotos en modo horizontal. Esto permitirá una mayor amplitud de visión al vehículo.
          </p>
        </div>
      </div>

      {
        //MOBILE VERSION
      }

      <div className="w-100 pe-5 ps-5 pt-4 d-md-none d-block">
        <Slider>
          <div className="d-flex justify-content-center flex-column align-content-center align-items-center p-4">
            <div>
              <img src={pic1} alt="" />
            </div>
            <p className="text_modal_instrucciones_inicio pe-4 ps-4 pt-3">
              Busca un lugar amplio y con buena luz donde puedas sacarle fotos a tu vehículo desde varios ángulos.
              (Serán 11 fotos en total.)
            </p>
          </div>
          <div className="d-flex justify-content-center flex-column align-content-center align-items-center p-4">
            <div>
              <img src={pic2} alt="" />
            </div>
            <p className="text_modal_instrucciones_inicio pe-2 ps-2 pt-3">
              Intentá que la foto este lo mas enfocada posible y que el vehículo se vea en su totalidad dentro del
              encuadre.
            </p>
          </div>
          <div className="d-flex justify-content-center flex-column align-content-center align-items-center p-4">
            <div>
              <img src={pic3} alt="" />
            </div>
            <p className="text_modal_instrucciones_inicio pe-2 ps-2 pt-3">
              Debes sacar las fotos en modo horizontal. Esto permitirá una mayor amplitud de visión al vehículo.
            </p>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default InstructivoFotos;
