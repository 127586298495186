import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import advertencia from "../../assets/img/advertencia.png";
import ModalAyuda from "../ModalAyuda/ModalAyuda";
import loading from "../../assets/img/loading.svg";
const Error404 = () => {
  const [modalAyudaShow, setModalAyudaShow] = useState(false);

  return (
    <div style={{ height: "600px", paddingTop: "200px", paddingBottom: "200px" }}>
      <ModalAyuda show={modalAyudaShow} onHide={() => setModalAyudaShow(false)} />
      <Col
        md={9}
        xs={11}
        className="mx-auto d-flex justify-content-center align-content-center align-items-center flex-column"
      >
        {/* <img src={advertencia} alt="Hubo un error al buscar los vehículos" style={{ maxWidth: "100px" }} />
        <p className="noVehicles text-center pt-4">No encontramos lo que estás buscando...</p>
        <p className="txtAyudamos text-center">Podemos ayudarte a encontrarlo!</p>
        <button className="btn btnBuscar" onClick={() => setModalAyudaShow(true)}>
          Dejanos tus datos aquí
        </button> */}
        <Row>
          <Col md={12} className="mx-auto text-center pt-5 pb-5">
            <img src={loading} alt="Cargando" />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Error404;
