import React, { useContext, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { VehiculosContext } from "../../context/VehiculosContext";
import { useForm } from "react-hook-form";
import Select from "react-select";
const Filtros = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const { filtro, setFiltro } = useContext(VehiculosContext);

  const { register, handleSubmit, watch, setValue } = useForm();
  const onSubmit = (data) => {
    setFiltro(data);
  };

  const watchPrecioDesde = watch("precioDesde", 0);
  const watchPrecioHasta = watch("precioHasta", 100000000);

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedKm, setSelectedKm] = useState("");

  const handleBrandChange = (selectedOption) => {
    setSelectedBrand(selectedOption.value);
    setValue("marca", selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption.value);
    setValue("year", selectedOption.value);
  };

  const handleKmChange = (selectedOption) => {
    setSelectedKm(selectedOption.value);
    setValue("kilometros", selectedOption.value);
  };

  const yearOptions = [
    { value: "", label: "Año" },

    { value: "2016", label: "Desde 2016" },
    { value: "2017", label: "Desde 2017" },
    { value: "2018", label: "Desde 2018" },
    { value: "2019", label: "Desde 2019" },
    { value: "2020", label: "Desde 2020" },
    { value: "2021", label: "Desde 2021" },
    { value: "2022", label: "Desde 2022" },
    { value: "2023", label: "Desde 2023" },
  ];

  const mileageOptions = [
    { value: "", label: "Kilometraje" },
    { value: "10000", label: "Hasta 10.000" },
    { value: "30000", label: "Hasta 30.000" },
    { value: "50000", label: "Hasta 50.000" },
    { value: "100000", label: "Hasta 100.000" },
    { value: "all", label: "Todos" },
  ];

  const brandOptions = [
    { value: "", label: "Todas las marcas" },
    { value: "ALFA ROMEO", label: "Alfa Romeo" },
    { value: "AUDI", label: "Audi" },
    { value: "BMW", label: "Bmw" },
    { value: "CHEVROLET", label: "Chevrolet" },
    { value: "CHRYSLER", label: "Chrysler" },
    { value: "CITROEN", label: "Citroen" },
    { value: "DOGDE", label: "Dodge" },
    { value: "FIAT", label: "Fiat" },
    { value: "FORD", label: "Ford" },
    { value: "HONDA", label: "Honda" },
    { value: "HYUNDAI", label: "Hyundai" },
    { value: "JEEP", label: "Jeep" },
    { value: "KIA", label: "Kia" },
    { value: "LAND ROVER", label: "Land Rover" },
    { value: "MAZA", label: "Mazda" },
    { value: "MERCEDES BENZ", label: "Mercedes Benz" },
    { value: "MINI", label: "Mini" },
    { value: "MITSUBISHI", label: "Mitsubishi" },
    { value: "NISSAN", label: "Nissan" },
    { value: "PEUGEOT", label: "Peugeot" },
    { value: "PORSCHE", label: "Porsche" },
    { value: "RENAULT", label: "Renault" },
    { value: "ROVER", label: "Rover" },
    { value: "SEAT", label: "Seat" },
    { value: "SMART", label: "Smart" },
    { value: "SUBARU", label: "Subaru" },
    { value: "SUZUKI", label: "Suzuki" },
    { value: "TOYOTA", label: "Toyota" },
    { value: "VOLKSWAGEN", label: "Volkswagen" },
    { value: "VOLVO", label: "Volvo" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className="filtros">
          <Row>
            <Col md={12} className="mb-3">
              <Select
                {...register("marca")}
                options={brandOptions}
                defaultValue={selectedBrand}
                onChange={handleBrandChange}
                value={brandOptions.find((option) => option.value === selectedBrand)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3 mt-3">
              <Select
                {...register("kilometros")}
                options={mileageOptions}
                defaultValue={selectedKm}
                onChange={handleKmChange}
                value={mileageOptions.find((option) => option.value === selectedKm)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3 mt-3">
              <Select
                {...register("year")}
                options={yearOptions}
                defaultValue={selectedYear}
                onChange={handleYearChange}
                value={yearOptions.find((option) => option.value === selectedYear)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="titleFilter">Precios </p>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1 mt-1">
              <p className="titleFilter">Desde $ {parseInt(watchPrecioDesde).toLocaleString("de-DE")} </p>
              <input
                type="range"
                min="0"
                max="100000000"
                step="500000"
                {...register("precioDesde", {})}
                value={watchPrecioDesde}
                className="w-100 rangeMonti"
              />
            </Col>
          </Row>

          <Row>
            <Col className="mb-1 mt-2">
              <p className="titleFilter">Hasta $ {parseInt(watchPrecioHasta).toLocaleString("de-DE")} </p>
              <input
                type="range"
                min="1000000"
                max="100000000"
                step="500000"
                className="w-100 rangeMonti"
                value={watchPrecioHasta}
                {...register("precioHasta", {})}
              />
            </Col>
          </Row>

          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <input type="submit" className="btn btnBuscar ps-5 pe-5" value="Filtrar" />
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default Filtros;
