import React, { useEffect, useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import FiltroHeader from "../Filtros/FiltroHeader";
import Header from "../Header/Header";
import { getFirestore } from "../../config/firebase";
import loading from "../../assets/img/loading.svg";

const Slider = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const obtenerDatos = async () => {
      const db = getFirestore();
      try {
        const data = await db.collection("sliders-montironiusados").get();
        const arrayData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setLoading(false);

        setBanners(arrayData);
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, []);

  return loading ? (
    <Row>
      <Col md={12} className="mx-auto text-center pt-5 pb-5">
        <img src={loading} alt="Cargando" />
      </Col>
    </Row>
  ) : (
    <>
      <Container fluid className="p-0 sliderMain">
        <Header />

        <div className="d-md-block d-none">
          <Carousel
            fade
            prevLabel={null}
            nextLabel={null}
            indicators={null}
            activeIndex={index}
            onSelect={handleSelect}
          >
            {banners[0] && banners[0].desktop.filter((b) => b.show === true).length > 0
              ? banners[0] &&
                banners[0].desktop
                  .filter((b) => b.show === true)
                  .map((item, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Usados" />
                    </Carousel.Item>
                  ))
              : banners[0] &&
                banners[0].desktop.map((item, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Usados" />
                  </Carousel.Item>
                ))}
          </Carousel>
        </div>

        <div className="d-md-none d-block">
          <Carousel
            fade
            prevLabel={null}
            nextLabel={null}
            indicators={null}
            activeIndex={index}
            onSelect={handleSelect}
          >
            {banners[0] && banners[0].mobile.filter((b) => b.show === true).length > 0
              ? banners[0] &&
                banners[0].mobile
                  .filter((b) => b.show === true)
                  .map((item, index) => (
                    <Carousel.Item key={index}>
                      <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Usados" />
                    </Carousel.Item>
                  ))
              : banners[0] &&
                banners[0].mobile.map((item, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100 img-fluid" src={item.slider} alt="Montironi Usados" />
                  </Carousel.Item>
                ))}
          </Carousel>
        </div>
      </Container>
    </>
  );
};

export default Slider;
