import { forwardRef } from "react";
import loading from "../../assets/img/loading.svg";

const BoxPic = forwardRef(function (
  {
    title = "",
    desc = "",
    pictureURL = "",
    pictureDefault = "",
    handleChange = () => {},
    handleClick = () => {},
    uploading = false,
  },
  ref
) {
  console.log(ref);
  return (
    <div className="p-2 text-md-start text-center">
      <input
        name={title}
        type="file"
        ref={ref}
        onChange={(e) => handleChange(e, title)}
        style={{ display: "none" }}
        accept="image/jpeg, image/png"
        capture
      />

      <p className="text_box_foto" dangerouslySetInnerHTML={{ __html: desc }}></p>
      {uploading ? (
        <div className="w-100 d-flex justify-content-center align-content-center align-items-center loading_box text-md-start text-center">
          <div>
            <img src={loading} alt="Cargando" style={{ width: "40px" }} />
          </div>
        </div>
      ) : pictureURL ? (
        <div class="container_box_p_vender text-md-start text-center" onClick={() => handleClick(title)}>
          <img src={pictureURL} className="image picture_upload_thumb img-fluid mx-auto" alt="" />
          <div class="middle">
            <div class="text">Cambiar foto</div>
          </div>
        </div>
      ) : (
        <img
          src={pictureDefault}
          alt=""
          className="img-fluid picture_upload_thumb mx-auto"
          onClick={() => handleClick(title)}
        />
      )}
    </div>
  );
});

export default BoxPic;
