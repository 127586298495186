import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import arrowDown from "../../assets/img/arrow-down.png";

import frente from "../../assets/img/mocks/Desktop/frente.png";
import lateral_izquierdo from "../../assets/img/mocks/Desktop/lateral_izquierdo.png";
import lateral_derecho from "../../assets/img/mocks/Desktop/lateral_derecho.png";
import atras from "../../assets/img/mocks/Desktop/atras.png";
import tablero from "../../assets/img/mocks/Desktop/tablero.png";
import interior_frente from "../../assets/img/mocks/Desktop/interior_frente.png";
import interior_trasero from "../../assets/img/mocks/Desktop/interior_trasero.png";
import cubierta_1 from "../../assets/img/mocks/Desktop/cubierta_1.png";
import cubierta_2 from "../../assets/img/mocks/Desktop/cubierta_2.png";
import cubierta_3 from "../../assets/img/mocks/Desktop/cubierta_3.png";
import cubierta_4 from "../../assets/img/mocks/Desktop/cubierta_4.png";
import loading from "../../assets/img/loading.svg";

import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import axios from "axios";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { VehiculosContext } from "../../context/VehiculosContext";
import BoxPic from "./BoxPic";
import InstructivoFotos from "./InstructivoFotos";

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,

  // responsive: [
  //   {
  //     breakpoint: 1024,
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 3,
  //       infinite: true,
  //       dots: true,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       arrows: false,
  //       vertical: true,
  //       verticalSwiping: true,
  //     },
  //   },
  // ],
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn_verde_vender",
    cancelButton: "btn btn_verde_vender",
  },
  buttonsStyling: false,
});

const ModalFotos = (props) => {
  const { cotizador, setCotizador } = useContext(VehiculosContext);
  const sliderRef = useRef();

  const [actualProgress, setActualProgress] = useState(0);

  const [step, setStep] = useState(0);
  const [pictures, setPictures] = useState({
    pic_frente: null,
    pic_izquierda: null,
    pic_derecha: null,
    pic_atras: null,
    pic_tablero: null,
    pic_interior_frente: null,
    pic_interior_atras: null,
    pic_neumaticos_1: null,
    pic_neumaticos_2: null,
    pic_neumaticos_3: null,
    pic_neumaticos_4: null,
  });
  const [uploading, setUploading] = useState({
    pic_frente: false,
    pic_izquierda: false,
    pic_derecha: false,
    pic_atras: false,
    pic_tablero: false,
    pic_interior_frente: false,
    pic_interior_atras: false,
    pic_neumaticos_1: false,
    pic_neumaticos_2: false,
    pic_neumaticos_3: false,
    pic_neumaticos_4: false,
  });

  const hidden_pic_frente = useRef(null);
  const hidden_pic_izquierda = useRef(null);
  const hidden_pic_derecha = useRef(null);
  const hidden_pic_atras = useRef(null);
  const hidden_pic_tablero = useRef(null);
  const hidden_pic_interior_frente = useRef(null);
  const hidden_pic_interior_atras = useRef(null);
  const hidden_pic_neumaticos_1 = useRef(null);
  const hidden_pic_neumaticos_2 = useRef(null);
  const hidden_pic_neumaticos_3 = useRef(null);
  const hidden_pic_neumaticos_4 = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (target) => {
    //alerta si estas cargando una foto, espera a que termine de subir para subir la siguiente
    if (Object.keys(uploading).filter((x) => uploading[x] === true).length) {
      swalWithBootstrapButtons.fire({
        title: "Se esta subiendo una foto, por favor espere a que termine para subir la siguiente",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Volver",
      });
    } else {
      if (target === "pic_frente") {
        hidden_pic_frente.current.click();
      }
      if (target === "pic_izquierda") {
        hidden_pic_izquierda.current.click();
      }
      if (target === "pic_derecha") {
        hidden_pic_derecha.current.click();
      }
      if (target === "pic_atras") {
        hidden_pic_atras.current.click();
      }
      if (target === "pic_tablero") {
        hidden_pic_tablero.current.click();
      }
      if (target === "pic_interior_frente") {
        hidden_pic_interior_frente.current.click();
      }
      if (target === "pic_interior_atras") {
        hidden_pic_interior_atras.current.click();
      }
      if (target === "pic_neumaticos_1") {
        hidden_pic_neumaticos_1.current.click();
      }
      if (target === "pic_neumaticos_2") {
        hidden_pic_neumaticos_2.current.click();
      }
      if (target === "pic_neumaticos_3") {
        hidden_pic_neumaticos_3.current.click();
      }
      if (target === "pic_neumaticos_4") {
        hidden_pic_neumaticos_4.current.click();
      }
    }
  };

  const validateImage = (file) => {
    if (!file) {
      swalWithBootstrapButtons.fire({
        title: "Selecciona una imágen",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Volver",
      });

      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      swalWithBootstrapButtons.fire({
        title: "Selecciona una imágen válida",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Volver",
      });
      return false;
    }

    return true;
  };

  const validateImageSize = (file) => {
    var fileSize = Math.round(file.size / 1024);

    if (fileSize <= 10 * 1024) {
      return true;
    } else {
      swalWithBootstrapButtons.fire({
        title: "El archivo es demasiado grande",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Volver",
      });

      return false;
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event, target) => {
    const fileUploaded = event.target.files[0];

    if (validateImage(fileUploaded)) {
      if (validateImageSize(fileUploaded)) {
        setUploading({ ...uploading, [target]: true });
        const form = new FormData();
        form.append("image", fileUploaded);

        const uploadImage = async () => {
          const response = await fetch(`https://api.imgbb.com/1/upload?&key=${process.env.REACT_APP_IMGBB}`, {
            method: "POST",
            body: form,
          }).then((response) => response.json());

          setPictures({ ...pictures, [target]: response.data });

          setCotizador({ ...cotizador, [target]: response.data.display_url });

          setUploading({ ...uploading, [target]: false });

          sliderRef.current.slickGoTo(sliderRef.current + 1);
        };

        uploadImage();
      }
    }
  };

  //SHOW PROGRESS
  useEffect(() => {
    let totalImages = Object.keys(pictures).filter((x) => pictures[x] !== null).length;

    let max = 100;

    if (max && totalImages) {
      if (totalImages === 11) {
        setActualProgress(100);
      } else {
        let percentComplete;
        if (totalImages > 0) {
          percentComplete = (totalImages / 11) * 100;
        } else {
          percentComplete = 100;
        }

        setActualProgress(Math.floor(percentComplete));
      }
    }
  }, [pictures]);

  const picturesForUpload = [
    {
      ref: hidden_pic_frente,
      title: "pic_frente",
      desc: "<b>Frente</b> <br /> con puertas cerradas",

      pictureURL: pictures?.pic_frente?.display_url,
      uploading: uploading?.pic_frente,
      pictureDefault: frente,
    },

    {
      ref: hidden_pic_izquierda,
      title: "pic_izquierda",
      desc: "<b>Lateral Izquierdo</b> <br /> 45° grados",

      pictureURL: pictures?.pic_izquierda?.display_url,
      uploading: uploading?.pic_izquierda,
      pictureDefault: lateral_izquierdo,
    },

    {
      ref: hidden_pic_derecha,
      title: "pic_derecha",
      desc: "<b>Lateral Derecho</b> <br /> 45° grados",

      pictureURL: pictures?.pic_derecha?.display_url,
      uploading: uploading?.pic_derecha,
      pictureDefault: lateral_derecho,
    },

    {
      ref: hidden_pic_atras,
      title: "pic_atras",
      desc: "<b>Lado trasero</b> <br /> completo",

      pictureURL: pictures?.pic_atras?.display_url,
      uploading: uploading?.pic_atras,
      pictureDefault: atras,
    },

    {
      ref: hidden_pic_tablero,
      title: "pic_tablero",
      desc: " <b>Tablero</b> <br /> con motor en marcha",

      pictureURL: pictures?.pic_tablero?.display_url,
      uploading: uploading?.pic_tablero,
      pictureDefault: tablero,
    },

    {
      ref: hidden_pic_interior_frente,
      title: "pic_interior_frente",
      desc: "<b>Interior</b> <br /> asientos frente",

      pictureURL: pictures?.pic_interior_frente?.display_url,
      uploading: uploading?.pic_interior_frente,
      pictureDefault: interior_frente,
    },

    {
      ref: hidden_pic_interior_atras,
      title: "pic_interior_atras",
      desc: "<b>Interior</b> <br /> asientos traseros",

      pictureURL: pictures?.pic_interior_atras?.display_url,
      uploading: uploading?.pic_interior_atras,
      pictureDefault: interior_trasero,
    },

    {
      ref: hidden_pic_neumaticos_1,
      title: "pic_neumaticos_1",
      desc: "<b>Neumático 1</b> <br /> que se vea el dibujo",

      pictureURL: pictures?.pic_neumaticos_1?.display_url,
      uploading: uploading?.pic_neumaticos_1,
      pictureDefault: cubierta_1,
    },

    {
      ref: hidden_pic_neumaticos_2,
      title: "pic_neumaticos_2",
      desc: "<b>Neumático 2</b> <br /> que se vea el dibujo",

      pictureURL: pictures?.pic_neumaticos_2?.display_url,
      uploading: uploading?.pic_neumaticos_2,
      pictureDefault: cubierta_2,
    },

    {
      ref: hidden_pic_neumaticos_3,
      title: "pic_neumaticos_3",
      desc: "<b>Neumático 3</b> <br /> que se vea el dibujo",

      pictureURL: pictures?.pic_neumaticos_3?.display_url,
      uploading: uploading?.pic_neumaticos_3,
      pictureDefault: cubierta_3,
    },

    {
      ref: hidden_pic_neumaticos_4,
      title: "pic_neumaticos_4",
      desc: "<b>Neumático 4</b> <br /> que se vea el dibujo",

      pictureURL: pictures?.pic_neumaticos_4?.display_url,
      uploading: uploading?.pic_neumaticos_4,
      pictureDefault: cubierta_4,
    },
  ];

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="pt-3">
        {step === 0 && (
          <div>
            <h4 className="title_modal_vender">
              Te contamos como subir imágenes de tu <br className="d-md-block d-none" />
              vehículo para poder cotizarlo.{" "}
            </h4>
            <h5 className="subtitle_modal_vender text-center pt-4">Antes de comenzar</h5>
            <div className="mx-auto text-center">
              <img src={arrowDown} alt="" className="mx-auto" />
            </div>

            <InstructivoFotos />

            <div className="text-center pt-4 pb-4">
              <Button
                variant="success"
                type="button"
                className="btn_verde_vender ms-4 ps-5 pe-5 pt-2 pb-2"
                onClick={() => setStep(1)}
              >
                Comenzar
              </Button>
            </div>
          </div>
        )}
        {step === 1 && (
          <div>
            <div className="d-flex flex-column justify-content-start ps-4 pe-4">
              <h4 className="title_modal_vender_paso_2 pb-2">EMPECEMOS</h4>
              <p className="desc_moda_vender_paso_2 pt-2 pb-4">
                Para obtener resultados precisos, te recomendamos seguir el instructivo paso a paso y utilizar los
                ejemplos proporcionados como referencia visual. Estos te servirán como guía para capturar fotos
                similares con tu celular.
              </p>
            </div>
            <div className="d-flex justify-content-start pb-4 ps-3 pe-3 mx-auto flex-column" style={{ width: "97%" }}>
              <div className="d-flex justify-content-between flex-row box_text_progress w-100 pb-2">
                <p>Proceso de carga</p>
                <p>{actualProgress}%</p>
              </div>
              <ProgressBar now={actualProgress} variant="success" style={{ height: "4px" }} />
            </div>
            <div className="pb-4 ps-3 pe-3 mx-auto" style={{ width: "97%" }}>
              {" "}
              <div className="d-md-block d-none w-100">
                <Slider {...settings} ref={sliderRef}>
                  {picturesForUpload.map((d) => (
                    <BoxPic
                      ref={d.ref}
                      title={d.title}
                      desc={d.desc}
                      pictureURL={d.pictureURL}
                      uploading={d.uploading}
                      pictureDefault={d.pictureDefault}
                      handleChange={handleChange}
                      handleClick={handleClick}
                    />
                  ))}
                </Slider>
              </div>
              <div className="d-md-none d-block">
                {picturesForUpload.map((d) => (
                  <BoxPic
                    ref={d.ref}
                    title={d.title}
                    desc={d.desc}
                    pictureURL={d.pictureURL}
                    uploading={d.uploading}
                    pictureDefault={d.pictureDefault}
                    handleChange={handleChange}
                    handleClick={handleClick}
                  />
                ))}
              </div>
            </div>
            {Object.keys(pictures).filter((x) => pictures[x] === null).length === 0 && (
              <div className="text-center pt-4 pb-4">
                <Button
                  variant="success"
                  type="button"
                  className="btn_verde_vender ms-4 ps-5 pe-5 pt-2 pb-2"
                  onClick={() => setStep(2)}
                >
                  Continuar
                </Button>
              </div>
            )}
          </div>
        )}
        {step === 2 && (
          <div className="d-flex flex-column justify-content-start ps-4 pe-4">
            <h4 className="title_modal_vender_paso_2 pb-0 border-0 mb-0">Listo completaste el paso 3. </h4>
            <h5 className="desc_moda_vender_paso_2 pt-2 pb-2"> Ya podes continuar con el siguiente paso.</h5>
            <p className="subdesc_moda_vender_paso_2 pt-2 pb-4">
              No olvides revisar las imágenes para asegurarte de que reflejen correctamente los pasos seguidos.{" "}
            </p>

            <Row className="d-flex flex-column flex-md-row">
              <Col className="w-100-mobile text-center p-md-0 p-2">
                <img src={pictures.pic_frente.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_izquierda.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_derecha.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_atras.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_tablero.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_interior_frente.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
            </Row>
            <Row className="d-flex flex-column flex-md-row">
              <Col className="w-100-mobile text-center p-md-0 p-2">
                <img src={pictures.pic_interior_atras.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_neumaticos_1.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_neumaticos_2.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_neumaticos_3.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
              <Col className="w-100-mobile text-center p-md-0 p-2">
                {" "}
                <img src={pictures.pic_neumaticos_4.display_url} className="picture_upload_thumb img-fluid" alt="" />
              </Col>
            </Row>

            <div className="d-flex justify-content-center align-content-center align-items-center pt-4">
              <div>
                <button className={"vender_siguiente"} onClick={() => setStep(1)}>
                  Quiero modificar las fotos
                </button>
              </div>
              <div>
                {Object.keys(pictures).filter((x) => pictures[x] === null).length === 0 && (
                  <div className="text-center pt-4 pb-4">
                    <Button
                      variant="success"
                      type="button"
                      className="btn_verde_vender ms-4 ps-5 pe-5 pt-2 pb-2"
                      onClick={props.onHide}
                    >
                      Continuar
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalFotos;
