import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Vehiculo from "./Vehiculo";
import WspFloat from "../WspFloat/WspFloat";

import Filtros from "../Filtros/Filtros";
import loading from "../../assets/img/loading.svg";
import advertencia from "../../assets/img/advertencia.png";

import { VehiculosContext } from "../../context/VehiculosContext";
import Pagination from "react-js-pagination";
import ModalAyuda from "../ModalAyuda/ModalAyuda";

const Vehiculos = () => {
  //extraer las recetas con distructuring
  const { vehiculosFiltrados, filtro, cargando, error, todosPerPage, activePage, handlePageChange } =
    useContext(VehiculosContext);

  // Logic for displaying current todos
  const indexOfLastTodo = activePage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const vehiculosOrdenadosPorPrioridad = [...vehiculosFiltrados].sort((a, b) => {
    // Convertimos los valores destacados a enteros
    const destacadoA = parseInt(a.destacado);
    const destacadoB = parseInt(b.destacado);

    // Ordenamos primero los destacados (1) y luego los no destacados (0)
    return destacadoB - destacadoA;
});
  const resultsForPaginate = vehiculosOrdenadosPorPrioridad.slice(indexOfFirstTodo, indexOfLastTodo);

  const [modalAyudaShow, setModalAyudaShow] = useState(false);

  return (
    <Container className="mt-2 vehiculos">
      {cargando ? (
        <Row>
          <Col md={3}>
            <Filtros filtro={filtro} />
          </Col>
          <Col md={9} className="mx-auto text-center pt-5 pb-5">
            <img src={loading} alt="Cargando" />
          </Col>
        </Row>
      ) : null}
      {vehiculosFiltrados.length === 0 && !cargando ? (
        error ? (
          <Row>
            <Col md={12} className="text-center">
              <img src={advertencia} alt="Hubo un error al buscar los vehículos" />
              <p className="font-weight-bold alert alert-danger text-center mt-4">
                Hubo un error al buscar los vehículos
              </p>
            </Col>
          </Row>
        ) : (
          <>
            <ModalAyuda show={modalAyudaShow} onHide={() => setModalAyudaShow(false)} />
            <Row className="d-none d-md-block">
              <Col md={12}>
                <h1 className="mt-0 pt-3">Elegí tu usado según tu marca favorita</h1>
              </Col>
            </Row>
            <Row>
              <Col md={3} className="d-sm-block d-none">
                <Filtros filtro={filtro} />
              </Col>
              <Col
                md={9}
                xs={11}
                className="mx-auto d-flex justify-content-center align-content-center align-items-center flex-column"
              >
                <img src={advertencia} alt="Hubo un error al buscar los vehículos" style={{ maxWidth: "100px" }} />
                <p className="noVehicles text-center pt-4">No encontramos el vehículo que estás buscando</p>
                <p className="txtAyudamos text-center">Podemos ayudarte a encontrarlo!</p>
                <button className="btn btnBuscar" onClick={() => setModalAyudaShow(true)}>
                  Dejanos tus datos aquí
                </button>

                <button
                  className="btn btnBuscar mt-4"
                  onClick={() => window.location.reload()}
                  style={{ backgroundColor: "#E2E2E2", color: "black" }}
                >
                  Quitar filtros
                </button>
              </Col>
            </Row>
          </>
        )
      ) : null}
      {!cargando && vehiculosFiltrados.length > 0 ? (
        <Row>
          <Col>
            <Row className="d-none d-md-block">
              <Col md={12}>
                <h1 className="mt-0 pt-3">Elegí tu usado según tu marca favorita</h1>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={11} className="mx-sm-0 mx-auto mb-4 mb-sm-0 ">
                <Filtros filtro={filtro} />
              </Col>
              <Col md={9} xs={11} className="mx-sm-0 mx-auto">
                <Row className="resultadosVehiculos" id="resultadosVehiculos">
                  <Vehiculo vehiculos={resultsForPaginate} />
                </Row>
                <Row>
                  {!cargando && vehiculosFiltrados.length > 6 ? (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={6}
                      totalItemsCount={vehiculosFiltrados.length}
                      pageRangeDisplayed={6}
                      onChange={handlePageChange}
                      innerClass="pagination justify-content-center"
                      itemClass="page-item"
                      linkClass="page-link"
                      hideDisabled="true"
                      hideFirstLastPages="true"
                      prevPageText="Anterior"
                      nextPageText="Siguiente"
                    />
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
      <WspFloat info="" />
    </Container>
  );
};

export default Vehiculos;
