import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import usadosEstaticos from "./usadosEstaticos.json";

//Crear el context
export const VehiculosContext = createContext();

//provider es donde se encuentran las funciones y state
const VehiculosProvider = (props) => {
  const filtrador = function filter(array, filters = {}) {
    let results = array;
    for (let key in filters) {
      const [field, kind] = key.split("_");
      switch (kind) {
        case "min": {
          let value = Number(filters[key]);
          results = results.filter((entry) => entry[field] >= value);
          break;
        }
        case "max": {
          let value = Number(filters[key]);
          results = results.filter((entry) => entry[field] <= value);
          break;
        }
        case "includes":
          results = results.filter((entry) => entry[field].includes(filters[key]));
          break;
        case undefined:
        case "equals":
          // eslint-disable-next-line eqeqeq
          results = results.filter((entry) => entry[field] == filters[key]);
          break;
      }
    }
    return results;
  };

  const [vehiculos, setVehiculos] = useState([]);
  const [vehiculosFiltrados, setVehiculosFiltrados] = useState([]);
  const [filtro, setFiltro] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(false);
  const [cotizador, setCotizador] = useState({
    nombre: "",
    email: "",
    telefono: "",
    year: "",
    km: "",
    marca: "",
    modelo: "",
    version: "",
    pic_frente: "",
    pic_izquierda: "",
    pic_derecha: "",
    pic_atras: "",
    pic_tablero: "",
    pic_interior_frente: "",
    pic_interior_atras: "",
    pic_neumaticos_1: "",
    pic_neumaticos_2: "",
    pic_neumaticos_3: "",
    pic_neumaticos_4: "",
  });

  const todosPerPage = 6;
  const [activePage, setCurrentPage] = useState(1);

  //ME TRAIGO LOS VEHICULOS
  useEffect(() => {
    const obtenerVehiculos = async () => {
      setCargando(true);
      try {
        //const respuesta = await axios.get("https://marketing.montironi.com/montironiusadosapi/api/");
        //const respuesta = await axios.get("https://marketing.montironi.com/montironiusadosapi/estaticos.json");
        // const respuesta = usadosEstaticos;

        const respuesta = await axios.post("https://api.webapp.montironi.com/api/cotizados", {
          id: "",
          patente: "",
          pagesize: 700,
          pageNumber: 1,
        });

        // Verificar si la respuesta tiene datos y si hay vehículos
        if (respuesta && respuesta.data && respuesta.data.vehicles && respuesta.data.vehicles.length > 0) {
          setVehiculos(respuesta.data.vehicles);
          setVehiculosFiltrados(respuesta.data.vehicles);
        } else {
          setVehiculos([]);
          setVehiculosFiltrados([]);
        }

        setCurrentPage(1);
      } catch (error) {
        //console.log("entro al catch y el error es", error);
        // Manejar errores de red
        if (!error.response) {
          //  console.log("Error de red: ", error.message);
        } else {
          // Manejar errores en la respuesta
          //  console.log("Error en la respuesta: ", error.response.data);
        }
        setError(true);
      } finally {
        // Independientemente de si hay éxito o error, detener la carga
        setCargando(false);
      }
    };

    obtenerVehiculos();
  }, []);

  //FILTRAR LOS VEHICULOS
  useEffect(() => {
    if (filtro) {
      const filtroFinal = {};

      if (filtro.marca) {
        filtroFinal.marca_includes = filtro.marca;
      }

      if (filtro.year) {
        filtroFinal.year_min = filtro.year;
      }

      if (filtro.kilometros) {
        filtroFinal.kilometros_max = filtro.kilometros;
      }

      if (filtro.precioDesde) {
        filtroFinal.precio_min = filtro.precioDesde;
      }

      if (filtro.precioHasta) {
        filtroFinal.precio_max = filtro.precioHasta;
      }

      const results = filtrador(vehiculos, filtroFinal);

      setVehiculosFiltrados(results);
      setCurrentPage(1);
    }
  }, [filtro]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <VehiculosContext.Provider
      value={{
        vehiculosFiltrados,
        filtro,
        setFiltro,
        cargando,
        error,
        todosPerPage,
        activePage,
        setCurrentPage,
        handlePageChange,
        cotizador,
        setCotizador,
      }}
    >
      {props.children}
    </VehiculosContext.Provider>
  );
};

export default VehiculosProvider;
