import { useEffect } from "react";
import { Step, Stepper } from "react-form-stepper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn_verde_vender",
    cancelButton: "btn btn_verde_vender",
  },
  buttonsStyling: false,
});

const Steps = ({ activeStep, handleStep, step0Completed, step1Completed, step2Completed, step3Completed }) => {
  const realStatus = (step) => {
    if (step === 0) {
      if (!step0Completed) {
        return "customStepSingle inactivo";
      }
      if (step0Completed) {
        return "customStepSingle activo completado";
      }
    }
    if (step === 1) {
      if (!step1Completed) {
        return "customStepSingle inactivo";
      }
      if (step1Completed) {
        return "customStepSingle activo completado";
      }
    }
    if (step === 2) {
      if (!step2Completed) {
        return "customStepSingle inactivo";
      }
      if (step2Completed) {
        return "customStepSingle activo completado";
      }
    }
    if (step === 3) {
      if (!step3Completed) {
        return "customStepSingle inactivo";
      }
      if (step3Completed) {
        return "customStepSingle activo completado";
      }
    }
    if (step === 4) {
      if (!step0Completed || !step1Completed || !step2Completed || !step3Completed) {
        return "customStepSingle inactivo";
      }
      if (step0Completed && step1Completed && step2Completed && step3Completed) {
        return "customStepSingle activo completado";
      }
    }
  };

  const isGrey = (step) => {
    if (step === 0) {
      if (!step0Completed) {
        return "labelStep inactivo";
      }
      if (step0Completed) {
        return "labelStep activo completado";
      }
    }
    if (step === 1) {
      if (!step1Completed) {
        return "labelStep inactivo";
      }
      if (step1Completed) {
        return "labelStep activo completado";
      }
    }
    if (step === 2) {
      if (!step2Completed) {
        return "labelStep inactivo";
      }
      if (step2Completed) {
        return "labelStep activo completado";
      }
    }
    if (step === 3) {
      if (!step3Completed) {
        return "labelStep inactivo";
      }
      if (step3Completed) {
        return "labelStep activo completado";
      }
    }
    if (step === 4) {
      if (!step0Completed || !step1Completed || !step2Completed || !step3Completed) {
        return "labelStep inactivo";
      }
      if (step0Completed && step1Completed && step2Completed && step3Completed) {
        return "labelStep activo completado";
      }
    }
  };

  const fire = () => {
    swalWithBootstrapButtons.fire({
      title: "Debes completar los pasos anteriores para continuar",
      icon: "warning",
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Volver",
    });
  };

  const validateCompleteStep = (step) => {
    if (step === 0) {
      handleStep(step);
    }

    if (step === 1) {
      if (step0Completed) {
        handleStep(step);
      } else {
        fire();
      }
    }

    if (step === 2) {
      if (step0Completed && step1Completed) {
        handleStep(step);
      } else {
        fire();
      }
    }

    if (step === 3) {
      if (step0Completed && step1Completed && step2Completed) {
        handleStep(step);
      } else {
        fire();
      }
    }

    if (step === 4) {
      if (step0Completed && step1Completed && step2Completed && step3Completed) {
        handleStep(step);
      } else {
        fire();
      }
    }
  };

  return (
    <>
      <Stepper
        activeStep={activeStep}
        stepClassName="customStepComponent"
        connectorStateColors={true}
        completedColor={"green"}
        activeColor={"yellow"}
      >
        <Step
          label={["", <div className={`${isGrey(1)}`}>Paso 1</div>, ""]}
          onClick={() => validateCompleteStep(0)}
          className={realStatus(0)}
        />
        <Step
          label={["", <div className={`${isGrey(1)}`}>Paso 2</div>, ""]}
          onClick={() => validateCompleteStep(1)}
          className={realStatus(1)}
        />
        <Step
          label={["", <div className={`${isGrey(2)}`}>Paso 3</div>, ""]}
          onClick={() => validateCompleteStep(2)}
          className={realStatus(2)}
        />
        <Step
          label={["", <div className={`${isGrey(3)}`}>Paso 4</div>, ""]}
          onClick={() => validateCompleteStep(3)}
          className={realStatus(3)}
        />
        <Step
          label={["", <div className={`${isGrey(4)}`}>Enviar</div>, ""]}
          onClick={() => validateCompleteStep(4)}
          className={realStatus(4)}
        />
      </Stepper>

      {/* <Stepper
        steps={[{ label: "Step 1" }, { label: "Step 2" }, { label: "Step 3" }]}
        activeStep={3}
        connectorStateColors
        nonLinear
      /> */}
    </>
  );
};

export default Steps;
