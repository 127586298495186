import React from "react";
import { Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import ImgNoDisponible from "../../assets/img/vehiculoNoDisponible.png";

const Vehiculo = ({ vehiculos, size, limit }) => {
  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  let utms = "";
  if (utm_source !== null && utm_medium !== null) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  const addDefaultSrc = (e) => {
    e.target.src = ImgNoDisponible;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getValidImageUrl(pictures) {
    // Revisa si hay imágenes disponibles
    //PARA NUESTRO BY PASS
    //if (!pictures || JSON.parse(pictures).length === 0) {
    if (!pictures || pictures.length === 0) {
      return ImgNoDisponible;
    }

    try {
      const imagesArray = pictures;
      // Encuentra la primera imagen que contenga '1_original' en la URL
      const validImage = imagesArray.find((image) => image.url.includes("1_original"));

      // Retorna la URL de la imagen válida o ImgNoDisponible si no se encuentra alguna
      return validImage ? validImage.url : ImgNoDisponible;
    } catch (error) {
      // Si hay un error al analizar el JSON, muestra ImgNoDisponible
      console.error("Error parsing pictures:", error);
      return ImgNoDisponible;
    }
  }

  // debugger;
  return vehiculos.map((vehiculo) => (
    <Col md={size ? size : 4} key={vehiculo.idcotizacion}>
      {/* {vehiculo.pictures} */}
      <Link to={`/ampliar/${vehiculo.idcotizacion}`} style={{ textDecoration: "none" }}>
        <Card style={{ width: "100%", height: size && "100%", marginBottom: "20px", borderRadius: "10px" }}>
          <Link to={`/ampliar/${vehiculo.idcotizacion}${utms}`}>
            <img
              onError={addDefaultSrc}
              className="img-fluid thumbnail"
              src={getValidImageUrl(vehiculo?.pictures)}
              alt={vehiculo.modelo}
            />
          </Link>
          <Card.Body>
            <h3 className="precio">
              {" "}
              {vehiculo.precio === "0"
                ? "Consultá por Whatsapp"
                : parseInt(vehiculo.precio) > 1000000
                ? `$ ${parseInt(vehiculo.precio).toLocaleString()}`
                : `U$S ${parseInt(vehiculo.precio).toLocaleString()}`}
            </h3>
            <p className="subtitulo">
              {vehiculo.year} | {vehiculo.kilometros} Km. |{" "}
              {vehiculo.transmision === "Automática" ? "Automática" : "Manual"}
            </p>

            <h3 className="marca_modelo">
              {vehiculo.marca.toUpperCase()}
              {vehiculo.modelo.toUpperCase()}
              {vehiculo.version.toUpperCase()}
            </h3>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  ));
};

export default Vehiculo;
