import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { VehiculosContext } from "../../context/VehiculosContext";
import { useParams, Redirect, Link } from "react-router-dom";
import loading from "../../assets/img/loading.svg";
import ImgNoDisponible from "../../assets/img/vehiculoNoDisponibleGrande.png";
import WspFloat from "../WspFloat/WspFloat";
import { FaChevronLeft } from "react-icons/fa";
import { Link as LinkScroll } from "react-scroll";
import ImageGallery from "react-image-gallery";
import Formulario from "../Formulario/Formulario";
import { useHistory } from "react-router-dom";
import Error404 from "../Error404";
import Vehiculo from "./Vehiculo";
const AmpliarVehiculo = () => {
  let { vehiculosFiltrados, vehiculos } = useContext(VehiculosContext);
  let { id } = useParams();
  let history = useHistory();

  const [error, setError] = useState(false);
  const [productoAmpliado, setProductoAmpliado] = useState(null);
  const [images, setImages] = useState([]);
  const [loadingProductoAmpliado, setLoadingProductoAmpliado] = useState(true);

  useEffect(() => {
    if (vehiculosFiltrados) {
      const resultado = vehiculosFiltrados.filter((vehiculo) => vehiculo.idcotizacion === id);
      setLoadingProductoAmpliado(false);
      setProductoAmpliado(resultado[0]);
    } else {
      setError(true);
    }
  }, [id, vehiculosFiltrados]); /*----*/

  useEffect(() => {
    if (productoAmpliado) {
      //REACT IMAGE GALLERY
      setImages(
        //JSON.parse(productoAmpliado.pictures)
        productoAmpliado.pictures
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((picture) => ({
            original: `${picture.url}`,
            thumbnail: `${picture.url}`,
          }))
      );
    }
  }, [productoAmpliado]); /*----*/

  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana al inicio de la página
  }, [id]); // Se ejecutará cada vez que el valor de 'id' cambie

  return (
    <Container className="ampliarVehiculo">
      {loadingProductoAmpliado ? (
        <Row>
          <Col md={12} className="mx-auto text-center pt-5 pb-5">
            <img src={loading} alt="Cargando" />
          </Col>
        </Row>
      ) : null}

      {error ? <Redirect to="/" /> : null}

      {productoAmpliado && !error ? (
        <>
          <Row>
            <Link to="/" className="linkVolver" style={{ display: "block", width: "auto" }}>
              <FaChevronLeft style={{ fontSize: "16px" }} /> <span style={{ paddingTop: "5px" }}>Volver</span>
            </Link>
          </Row>
          <Row>
            <Col md={12} xs={11} className="text-md-start text-center mx-auto">
              <h1>
                {productoAmpliado.marca.toUpperCase()}
                {productoAmpliado.modelo.toUpperCase()}
                {productoAmpliado.version.toUpperCase()}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={8} key={productoAmpliado.id}>
              {images.length > 0 && images.some((image) => image.original.includes("1_original")) ? (
                <Row>
                  <Col md={12} xs={11} className="mx-auto">
                    <ImageGallery items={images} thumbnailPosition="left" showPlayButton={false} />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={12} xs={11} className="mx-auto">
                    <img src={ImgNoDisponible} alt="imagen no disponible" width="100%" />
                  </Col>
                </Row>
              )}
            </Col>

            <Col md={4} xs={11} className="mx-auto d-block">
              <div className="fichaDerecha  h-100 flex-column justify-content-around">
                <p className="subtitulo">
                  {productoAmpliado.year} | {parseInt(productoAmpliado.kilometros).toLocaleString()} Km. |{" "}
                  {productoAmpliado.transmision === "Automática" ? "Automática" : "Manual"}
                </p>
                <h1>
                  {productoAmpliado.marca.toUpperCase()}
                  {productoAmpliado.modelo.toUpperCase()}
                  {productoAmpliado.version.toUpperCase()}
                </h1>
                <h2>
                  {console.log()}{" "}
                  {productoAmpliado.precio === "0"
                    ? "Consultá por Whatsapp"
                    : parseInt(productoAmpliado.precio) > 1000000
                    ? `$ ${parseInt(productoAmpliado.precio).toLocaleString()}`
                    : `U$S ${parseInt(productoAmpliado.precio).toLocaleString()}`}
                </h2>

                <div className="ficha" style={{ border: 0, padding: 0, paddingTop: "25px" }}>
                  <Row>
                    <Col md={4} xs={12}>
                      <ul className="list-unstyled">
                        <li>
                          <h5>Año</h5>
                          <h4>{productoAmpliado.year}</h4>
                        </li>
                        <li>
                          <h5>Kilometraje</h5>
                          <h4>{parseInt(productoAmpliado.kilometros).toLocaleString()}</h4>
                        </li>
                        <li>
                          <h5>Combustible</h5>
                          <h4>{productoAmpliado.combustible}</h4>
                        </li>
                      </ul>
                    </Col>
                    <Col md={8} xs={12} className="mx-auto">
                      <ul className="list-unstyled">
                        <li>
                          <h5>Transmisión</h5>
                          <h4>{productoAmpliado.transmision === "Automática" ? "Automática" : "Manual"}</h4>
                        </li>
                        <li>
                          <h5>Versión</h5>
                          <h4>{productoAmpliado.version_vehicle}</h4>
                        </li>
                        <li>
                          <h5>Color</h5>
                          <h4>{productoAmpliado.color}</h4>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>

                <LinkScroll to="consultar" smooth={true}>
                  <Button variant="success" type="submit" className="btnGreen w-100">
                    Consultar
                  </Button>
                </LinkScroll>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={11} md={12} className="pt-5 mx-auto">
              <h1 className="text-center ">Articulos relacionados</h1>
            </Col>
          </Row>
          <Row className="resultadosVehiculos pb-5" id="resultadosVehiculos">
            <Col xs={11} md={12} className="pt-5 mx-auto">
              <Row>
                <Vehiculo
                  vehiculos={vehiculosFiltrados
                    .sort(() => Math.random() - Math.random())

                    .slice(0, 4)}
                  size={3}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={11} md={12} className="mx-auto">
              <div className="fichaDerecha d-block" style={{ border: 0, padding: 0 }}>
                <p className="solicitarAsesoramiento text-left">Solicitá asesoramiento</p>
                <div className="formVehiculo">
                  <Formulario
                    hideLabels="d-none"
                    separator="pt-4"
                    vMarca={productoAmpliado.marca}
                    vModelo={productoAmpliado.modelo}
                    vOrigen={`Origen: ${productoAmpliado.origen}`}
                    isHorizontal={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div id="consultar"></div>
          <WspFloat
            info={`%20Sobre%20${productoAmpliado.marca}%20${productoAmpliado.modelo}%20Link:%20https://montironiusados.com/ampliar/${id}`}
          />
        </>
      ) : (
        <Error404 />
      )}
    </Container>
  );
};

export default AmpliarVehiculo;
