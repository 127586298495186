import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoHeader from "../../assets/img/logo-header.svg";

const Header = ({ bg }) => {
  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  let utms = "";
  if (utm_source !== null && utm_medium !== null) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  let search = window.location.pathname;

  return (
    <Container fluid className={`header ${bg}`}>
      <Container>
        <Row>
          <Col md={6} xs={12} className="text-md-start text-center">
            <Link to={`/${utms}`}>
              <img src={logoHeader} alt="Montironi Usados" />
            </Link>
          </Col>
          <Col md={6} xs={12} className="text-md-end  text-center pt-md-2 pt-5 ">
            <Link className={`${search === "/" ? "btn btnBuscar mt-md-0 mt-3" : "p-4 linkhsimple"}`} to={`/${utms}`}>
              Quiero Comprar
            </Link>

            <Link
              className={`${search === "/vender" ? "btn btnBuscar mt-md-0 mt-3" : "p-4 linkhsimple"}`}
              to={`/vender${utms}`}
            >
              Vendé tu usado
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Header;
