import { Form, Col, Row, Button } from "react-bootstrap";
import { FaTemperatureHigh } from "react-icons/fa";
import { useState } from "react";
import emailjs from "emailjs-com";
import loading from "../../assets/img/loading.svg";
import check from "../../assets/img/check.png";
import { useParams } from "react-router-dom";

const Formulario = ({ hideLabels, separator, alturaMinima, vMarca, vModelo, vOrigen, isHorizontal }) => {
  let { id } = useParams();
  if (id === undefined) {
    id = "No seleccionado";
  }

  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState(null);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    coments: "",
    vehicle: `${vMarca} ${vModelo} ${id} ${vOrigen}`,
    origen: `${utm_source}-${utm_medium}`,
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.preventDefault();
      setStatus("cargando");
      emailjs
        .send("landingMontironi", "crm", toSend, "user_EIC1ELQk7I3NPaFMEDa64")
        .then((response) => {
          setStatus("ok");

          setTimeout(() => {
            setStatus(null);
            setValidated(false);
            setToSend({
              ...toSend,
              name: "",
              email: "",
              phone: "",
              coments: "",
              vehicle: "",
              origen: "",
            });
          }, 4000);
        })
        .catch((err) => {
          setStatus("error");
          setTimeout(() => {
            setStatus(null);
          }, 4000);
        });
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit} noValidate validated={validated}>
        <Row>
          <Col md={isHorizontal ? 6 : 12} className={separator}>
            <Form.Label className={hideLabels}>Nombre completo</Form.Label>
            <Form.Control
              id="name"
              name="name"
              placeholder="Tu nombre"
              value={toSend.name}
              onChange={handleChange}
              required
            />
          </Col>

          <Col md={isHorizontal ? 6 : 12} className={separator}>
            <Form.Label className={hideLabels}>Email</Form.Label>
            <Form.Control
              id="email"
              name="email"
              type="email"
              placeholder="email@email.com"
              value={toSend.email}
              onChange={handleChange}
              required
            />
          </Col>

          <Col md={isHorizontal ? 6 : 12} className={separator}>
            <Form.Label className={hideLabels}>Teléfono</Form.Label>
            <Form.Control
              id="phone"
              name="phone"
              placeholder="0351 X XXXXXX"
              value={toSend.phone}
              onChange={handleChange}
              required
            />
          </Col>

          <Col md={isHorizontal ? 6 : 12} className={separator}>
            <Form.Label className={hideLabels}>Comentarios</Form.Label>
            <textarea
              className="form-control"
              placeholder="Comentarios..."
              name="coments"
              id="coments"
              rows="1"
              onChange={handleChange}
              value={toSend.coments}
            >
              {toSend.coments}
            </textarea>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={`${separator} text-center`}>
            <Button variant="success" type="submit" className="btnGreen mx-auto">
              Enviar consulta
            </Button>
          </Col>
        </Row>
      </Form>

      {status === "cargando" && (
        <div className="msgHeader">
          <img src={loading} alt="Cargando" style={{ marginTop: "50px", marginBottom: "50px" }} />
        </div>
      )}

      {status === "ok" && (
        <div className="msgHeader">
          <div>
            <img src={check} alt="Enviado" className="img-fluid" style={{ marginBottom: "20px", maxWidth: "60px" }} />
            <p
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: "0",
                textAlign: "center",
              }}
            >
              Recibimos tu consulta
            </p>
            <p style={{ color: "green", textAlign: "center" }}>Nos comunicaremos a la brevedad!</p>
          </div>
        </div>
      )}

      {status === "error" && (
        <div className="msgHeader">
          <div>
            <p
              style={{
                color: "green",
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: "0",
                textAlign: "center",
              }}
            >
              Se ha producido un error
            </p>
            <p style={{ color: "green", textAlign: "center" }}>Vuelva a intentarlo mas tarde</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Formulario;
