import { Form, Col, Row, Button, Modal, ModalG } from "react-bootstrap";
import { useState } from "react";
import emailjs from "emailjs-com";
import loading from "../../assets/img/loading.svg";
import check from "../../assets/img/check.png";
import cancel from "../../assets/img/cancel.png";

const ModalAyuda = (props) => {
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState(null);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    vehicle: "",
    coments: "",
    origen: "BUSCANDO-USADO",
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.preventDefault();
      setStatus("cargando");
      emailjs
        .send("landingMontironi", "crm", toSend, "user_EIC1ELQk7I3NPaFMEDa64")
        .then((response) => {
          setStatus("ok");

          setTimeout(() => {
            setStatus(null);
            setValidated(false);
            setToSend({
              ...toSend,
              name: "",
              email: "",
              phone: "",
              vehicle: "",
              coments: "",
              origen: "BUSCANDO-USADO",
            });
          }, 4000);
        })
        .catch((err) => {
          setStatus("error");
          setTimeout(() => {
            setStatus(null);
          }, 4000);
        });
    }
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <div className="d-flex justify-content-between w-100">
          <h4 className="mb-0">¿Qué auto estás buscando?</h4>
          <img
            src={cancel}
            alt="Cerrar"
            className="img-fluid"
            style={{
              height: "15px",
              width: "15px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={props.onHide}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          {status === null && (
            <Form onSubmit={onSubmit} noValidate validated={validated}>
              <Row>
                <Col md={12}>
                  <Form.Label className="d-none">Nombre completo</Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    placeholder="Tu nombre completo"
                    value={toSend.name}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="pt-4">
                  <Form.Label className="d-none">Email</Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email@email.com"
                    value={toSend.email}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="pt-4">
                  <Form.Label className="d-none">Teléfono</Form.Label>
                  <Form.Control
                    id="phone"
                    name="phone"
                    placeholder="0351 X XXXXXX"
                    value={toSend.phone}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="pt-4">
                  <Form.Label className="d-none">¿Qué auto estás buscando?</Form.Label>
                  <Form.Control
                    id="vehicle"
                    name="vehicle"
                    placeholder="¿Qué auto estás buscando?"
                    value={toSend.vehicle}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="pt-4">
                  <Form.Label className="d-none">Comentarios</Form.Label>
                  <textarea
                    className="form-control"
                    placeholder="Comentarios..."
                    name="coments"
                    id="coments"
                    rows="3"
                    onChange={handleChange}
                    value={toSend.coments}
                  >
                    {toSend.coments}
                  </textarea>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="pt-4">
                  <Button variant="success" type="submit" className="btnBuscar w-100">
                    Consultar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          {status === "cargando" && (
            <div className="msgHeader text-center pt-5 pb-5">
              <img src={loading} alt="Cargando" style={{ marginTop: "50px", marginBottom: "50px" }} />
            </div>
          )}

          {status === "ok" && (
            <div className="msgHeader text-center pt-5 pb-5">
              <div>
                <img
                  src={check}
                  alt="Enviado"
                  className="img-fluid"
                  style={{ marginBottom: "20px", maxWidth: "60px" }}
                />
                <p
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginBottom: "0",
                    textAlign: "center",
                  }}
                >
                  Recibimos tu consulta
                </p>
                <p style={{ color: "green", textAlign: "center" }}>Nos comunicaremos a la brevedad!</p>

                <Button variant="success" type="button" className="btnBuscar mt-4" onClick={props.onHide}>
                  Continuar
                </Button>
              </div>
            </div>
          )}

          {status === "error" && (
            <div className="msgHeader">
              <div>
                <p
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginBottom: "0",
                    textAlign: "center",
                  }}
                >
                  Se ha producido un error
                </p>
                <p style={{ color: "green", textAlign: "center" }}>Vuelva a intentarlo mas tarde</p>
              </div>
            </div>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAyuda;
