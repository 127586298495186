//LISTA DE VEHICULOS
export const marcasVehiculos = [
  { value: "Alfa Romeo", label: "Alfa Romeo" },
  { value: "Audi", label: "Audi" },
  { value: "BMW", label: "BMW" },
  { value: "Chevrolet", label: "Chevrolet" },
  { value: "Chrysler", label: "Chrysler" },
  { value: "Citroen", label: "Citroen" },
  { value: "Dodge", label: "Dodge" },
  { value: "Fiat", label: "Fiat" },
  { value: "Ford", label: "Ford" },
  { value: "Honda", label: "Honda" },
  { value: "Hyundai", label: "Hyundai" },
  { value: "Jeep", label: "Jeep" },
  { value: "Kia", label: "Kia" },
  { value: "Land Rover", label: "Land Rover" },
  { value: "Mazda", label: "Mazda" },
  { value: "Mercedes Benz", label: "Mercedes Benz" },
  { value: "Mini", label: "Mini" },
  { value: "Mitsubishi", label: "Mitsubishi" },
  { value: "Nissan", label: "Nissan" },
  { value: "Peugeot", label: "Peugeot" },
  { value: "Porsche", label: "Porsche" },
  { value: "Renault", label: "Renault" },
  { value: "Rover", label: "Rover" },
  { value: "Seat", label: "Seat" },
  { value: "Smart", label: "Smart" },
  { value: "Subaru", label: "Subaru" },
  { value: "Suzuki", label: "Suzuki" },
  { value: "Toyota", label: "Toyota" },
  { value: "Volkswagen", label: "Volkswagen" },
  { value: "Volvo", label: "Volvo" },
];
