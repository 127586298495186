import wsp from "../../assets/img/wsp.png";

import "../../assets/scss/styles.scss";

const WspFloat = ({ info }) => {
  return (
    <>
      <a
        href={`https://api.whatsapp.com/send?phone=+5493517915736&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n${info}`}
        className="floatWsp"
        target="_blank"
        rel="noreferrer"
      >
        <img src={wsp} alt="Whatsapp" />
      </a>
    </>
  );
};

export default WspFloat;
