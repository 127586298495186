import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoHeader from "../../assets/img/logo-header.svg";

const Footer = () => {
  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  let utms = "";
  if (utm_source !== null && utm_medium !== null) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  return (
    <Container fluid className={`header bgDark`} style={{ zIndex: "2" }}>
      <Container>
        <Row>
          <Col
            md={6}
            xs={10}
            className="text-center d-flex justify-content-start align-content-start align-items-center mx-auto"
          >
            <div className="text-md-start text-center">
              <Link to={`/${utms}`}>
                <img src={logoHeader} alt="Montironi Usados" />
              </Link>

              <div className="footer pt-4">
                <p className="text-sm-start text-center footerText">
                  Al navegar en el sitio tené en cuenta que los precios y ofertas disponibles no son vinculantes y
                  pueden sufrir modificaciones sin previo aviso. Todas las fotos son a modo ilustrativo. <br />
                  Copyright © 2021 Montironi Usados. Todos los derechos reservados
                </p>
              </div>
            </div>
          </Col>

          <Col md={6} xs={12} className="text-center">
            <iframe
              title="Dopppler"
              id="doppler_subscription"
              src="https://app2.fromdoppler.com/Lists/FormProcessing/PublishedForm?IdForm=VSAFgNV%2b8qCYw%2fNql6YXmA%3d%3d"
              height="230"
              width="375"
              scrolling="no"
              frameborder="no"
              style={{ overflow: "hidden" }}
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
